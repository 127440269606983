.slider {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slides img {
  width: 100%;
  flex-shrink: 0;
}

.next {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.main-container {
  width: 100vw;
  /*메인 이미지 관련*/
  /*비전*/
  /*비즈니스*/
  /*서비스 소개*/
  /*연혁*/
  /*파트너*/
  /*뉴스*/
  /*뉴스레터*/
  /*앱 다운로드*/
}
.main-container .main-background-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  height: 100vh;
  background-image: url('../public/images/1.png');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
.main-container .main-background-container .main-title-container {
  margin: 0 auto;
  padding-right: 512px;
  font-size: 3.6rem;
  font-weight: 600;
  color: #fff;
}
.main-container
  .main-background-container
  .main-title-container
  .main-title-top {
  margin: 0 0 24px 0;
}
.main-container
  .main-background-container
  .main-title-container
  .main-title-bottom {
  margin: 0 0 48px 0;
}
.main-container
  .main-background-container
  .main-title-container
  .app-download-btn {
  border-radius: 16px;
  text-align: center;
  max-width: 200px;
  padding: 8px 12px;
  font-size: 2rem;
  color: white;
  background-color: var(--primary-color);
}
.main-container
  .main-background-container
  .main-title-container
  .download-container {
  display: flex;
}
.main-container
  .main-background-container
  .main-title-container
  .download-container
  .download-icon {
  width: 220px;
  margin-right: 24px;
}
.main-container .vision-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 128px 0;
  width: 1080px;
}
.main-container .vision-container .vision-index {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 48px;
}
.main-container .vision-container .vision-title {
  font-weight: 600;
  font-size: 2.4rem;
  margin-bottom: 32px;
}
.main-container .vision-container .vision-text {
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  line-height: 160%;
  margin-bottom: 96px;
}
.main-container .vision-container .vision-image-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  min-height: 600px;
  background-image: url('../public/images/2.png');
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  border-radius: 36px;
}
.main-container .vision-container .vision-image-container .vision-image-text {
  color: #fff;
  margin-left: 64px;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 8px;
}
.main-container .business-background {
  background: var(--grey-100);
}
.main-container .business-background .business-container {
  width: 1080px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 128px 0;
  margin: 0 auto;
}
.main-container .business-background .business-container .business-index {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 48px;
}
.main-container .business-background .business-container .business-section-one {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 128px;
}
.main-container
  .business-background
  .business-container
  .business-section-one
  .business-content-container {
  flex: 1;
}
.main-container
  .business-background
  .business-container
  .business-section-one
  .business-content-container
  .business-title {
  font-weight: 600;
  font-size: 2.4rem;
  margin-bottom: 32px;
}
.main-container
  .business-background
  .business-container
  .business-section-one
  .business-content-container
  .business-text {
  font-size: 1.4rem;
  line-height: 160%;
  margin-bottom: 96px;
}
.main-container
  .business-background
  .business-container
  .business-section-one
  .business-image {
  flex: 1;
  overflow: hidden;
  margin-left: 64px;
}
.main-container
  .business-background
  .business-container
  .business-section-one
  .business-image
  img {
  width: 100%;
  height: 600px;
  object-fit: cover;
  object-position: right;
  border-radius: 36px;
}
.main-container .business-background .business-container .business-section-two {
  display: flex;
  justify-content: center;
  flex-direction: row;
  /* margin-bottom: 128px;
  */
}
.main-container
  .business-background
  .business-container
  .business-section-two
  .business-content-container {
  flex: 1;
}
.main-container
  .business-background
  .business-container
  .business-section-two
  .business-content-container
  .business-title {
  font-weight: 600;
  font-size: 2.4rem;
  margin-bottom: 16px;
}
.main-container
  .business-background
  .business-container
  .business-section-two
  .business-content-container
  .business-title
  .business-title-wip {
  color: var(--grey-500);
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 32px;
}
.main-container
  .business-background
  .business-container
  .business-section-two
  .business-content-container
  .business-text {
  font-size: 1.4rem;
  line-height: 160%;
  margin-bottom: 96px;
}
.main-container
  .business-background
  .business-container
  .business-section-two
  .business-image {
  flex: 1;
  overflow: hidden;
  margin-right: 64px;
}
.main-container
  .business-background
  .business-container
  .business-section-two
  .business-image
  img {
  width: 100%;
  height: 420px;
  object-fit: cover;
  object-position: left;
  border-radius: 36px;
}
.main-container .use-container {
  width: 1080px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 128px 0;
  margin: 0 auto;
}
.main-container .use-container .use-index {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 48px;
}
.main-container .use-container .use-section .use-title {
  font-weight: 600;
  font-size: 2.4rem;
  margin-bottom: 48px;
}
.main-container .use-container .use-section .use-content-container {
  display: flex;
  align-items: center;
}
.main-container
  .use-container
  .use-section
  .use-content-container
  .use-image-slider {
  width: 100%;
  max-width: 240px;
  margin-right: 128px;
}
.main-container
  .use-container
  .use-section
  .use-content-container
  .use-image-slider
  img {
  width: 100%;
  max-width: 240px;
  height: auto;
}
.main-container
  .use-container
  .use-section
  .use-content-container
  .verticalDottedLine {
  z-index: 1;
  position: relative;
  /* 절대 위치 지정 */
  left: 32px;
  /* 왼쪽으로부터의 거리 */
  width: 0px;
  /* 라인의 두께 (0으로 설정하고, 경계선으로 라인을 만듭니다) */
  height: 400px;
  /* 라인의 높이 */
  border-left: 2px dotted black;
  /* 왼쪽 경계선을 점선 스타일로 설정 */
}
.main-container
  .use-container
  .use-section
  .use-content-container
  .use-list-container {
  z-index: 2;
  flex: 1;
}
.main-container
  .use-container
  .use-section
  .use-content-container
  .use-list-container
  .use-content-section {
  display: flex;
  flex-direction: row;
}
.main-container
  .use-container
  .use-section
  .use-content-container
  .use-list-container
  .use-content-section
  .use-stage {
  display: flex;
  width: 64px;
  min-width: 64px;
  height: 64px;
  min-height: 64px;
  background-color: var(--grey-500);
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  margin: 28px 24px 28px 0;
}
.main-container
  .use-container
  .use-section
  .use-content-container
  .use-list-container
  .use-content-section
  .use-stage.active {
  background-color: var(--primary-color);
}
.main-container
  .use-container
  .use-section
  .use-content-container
  .use-list-container
  .use-content-section
  .use-text {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 28px 24px 28px 0;
}
.main-container .use-container .space-container {
  margin-top: 192px;
}
.main-container .history-background {
  background-color: var(--grey-100);
}
.main-container .history-background .history-container {
  width: 1080px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 128px 0;
  margin: 0 auto;
  /* .history-title {
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 48px;
 }
  */
}
.main-container .history-background .history-container .history-index {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 48px;
}
.main-container
  .history-background
  .history-container
  .history-align-container {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  */
}
.main-container
  .history-background
  .history-container
  .history-align-container
  .history-content-container
  .history-section-container {
  margin-top: 64px;
  display: flex;
}
.main-container
  .history-background
  .history-container
  .history-align-container
  .history-content-container
  .history-section-container
  .history-year {
  min-width: 128px;
  font-size: 2rem;
  font-weight: bold;
  margin-right: 48px;
  text-align: end;
}
.main-container
  .history-background
  .history-container
  .history-align-container
  .history-content-container
  .history-section-container
  .history-text-container {
  display: flex;
  flex-direction: column;
}
.main-container
  .history-background
  .history-container
  .history-align-container
  .history-content-container
  .history-section-container
  .history-text-container
  .history-content {
  display: flex;
  margin-bottom: 32px;
}
.main-container
  .history-background
  .history-container
  .history-align-container
  .history-content-container
  .history-section-container
  .history-text-container
  .history-content
  .history-season {
  min-width: 64px;
  color: var(--primary-color);
  font-size: 2rem;
  font-weight: bold;
  margin-right: 24px;
  text-align: end;
}
.main-container
  .history-background
  .history-container
  .history-align-container
  .history-content-container
  .history-section-container
  .history-text-container
  .history-content
  .history-text-container
  .history-text {
  font-size: 1.2rem;
  margin: 8px 0;
}
.main-container
  .history-background
  .history-container
  .history-align-container
  .history-content-container
  .horizontal-line {
  margin: 16px 0;
  max-width: 100vw;
  height: 2px;
  background-color: var(--primary-color);
}
.main-container .partner-container {
  width: 1080px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 128px 0;
  margin: 0 auto;
}
.main-container .partner-container .partner-index {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 48px;
}
.main-container .partner-container .image-container {
  display: flex;
  flex-wrap: wrap;
}
.main-container .partner-container .image-container .partner-image {
  max-width: 360px;
  flex: 1 0 25%;
  max-width: 25%;
  padding: 16px;
  box-sizing: border-box;
}
.main-container .news-background {
  background-color: var(--grey-100);
}
.main-container .news-background .news-container {
  width: 1080px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 128px 0;
  margin: 0 auto;
}
.main-container .news-background .news-container .news-index {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 48px;
}
.main-container .news-background .news-container .news-section {
  max-width: 960px;
  display: flex;
  flex-direction: column;
}
.main-container .news-background .news-container .news-section .news-title {
  margin-top: 16px;
}
.main-container
  .news-background
  .news-container
  .news-section
  .news-title
  .news-info-container {
  display: flex;
  margin: 16px 0;
}
.main-container
  .news-background
  .news-container
  .news-section
  .news-title
  .news-info-container
  .news-date {
  color: var(--primary-color);
  font-size: 1rem;
  margin-right: 16px;
}
.main-container
  .news-background
  .news-container
  .news-section
  .news-title
  .news-info-container
  .news-company {
  color: var(--primary-color);
  font-size: 1rem;
  font-weight: bold;
}
.main-container
  .news-background
  .news-container
  .news-section
  .news-title
  .news-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
  color: black;
}
.main-container
  .news-background
  .news-container
  .news-section
  .news-title
  .news-link
  .news-link-title {
  font-size: 1.4rem;
  font-weight: bold;
  margin-right: 48px;
}
.main-container
  .news-background
  .news-container
  .news-section
  .news-title
  .news-link
  .news-arrow-icon {
  align-items: center;
  font-size: 1.6rem;
  font-weight: normal;
}
.main-container
  .news-background
  .news-container
  .news-section
  .news-title
  .news-link:visited {
  color: black;
}
.main-container
  .news-background
  .news-container
  .news-section
  .horizontal-line {
  margin: 16px 0;
  max-width: 960px;
  height: 2px;
  background-color: var(--grey-300);
}
.main-container .newsletter-container {
  width: 1080px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 128px 0;
  margin: 0 auto;
}
.main-container .newsletter-container .newsletter-index {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 48px;
}
.main-container .newsletter-container .newsletter-title {
  font-weight: 600;
  font-size: 2.4rem;
  margin-bottom: 48px;
}
.main-container .newsletter-container #stb_subscribe * {
  font-size: 1.4rem;
}
.main-container .newsletter-container #stb_subscribe {
  border: none;
  width: 60vw;
  padding: 0;
}
.main-container
  .newsletter-container
  #stb_subscribe
  .stb_form_set
  .stb_form_set_label
  .stb_asterisk {
  color: var(--primary-color);
}
.main-container
  .newsletter-container
  #stb_subscribe
  .stb_form_set
  .stb_form_set_input {
  border: none;
  background-color: var(--grey-200);
  border-radius: 8px;
  padding: 12px 16px;
  width: 80%;
}
.main-container
  .newsletter-container
  #stb_subscribe
  .stb_form_set
  .stb_form_set_input:focus {
  outline: none;
}
.main-container .newsletter-container #stb_subscribe .stb_form_policy * {
  font-size: 1.2rem !important;
}
.main-container
  .newsletter-container
  #stb_subscribe
  .stb_form_policy
  label
  button {
  color: var(--primary-color);
}
.main-container .newsletter-container #stb_form_submit_button {
  padding: 4px 24px !important;
  font-size: 1.6rem !important;
  font-weight: 600 !important;
  background-color: var(--grey-700) !important;
  border-radius: 8px !important;
}
.main-container .newsletter-container #stb_subscribe .stb_form_msg_error {
  font-size: 1.2rem;
  color: var(--primary-color);
}
.main-container
  .newsletter-container
  #stb_subscribe
  .stb_form_result.success
  p {
  color: var(--primary-color);
  font-size: 1.2rem;
}
.main-container .newsletter-container #stb_subscribe .stb_form_result.fail p {
  font-size: 1.2rem;
  color: var(--primary-color);
}
.main-container .bottom-background-container {
  width: 1080px;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 128px 0;
  margin: 0 auto;
  border-radius: 36px;
  background-image: url('../public/images/5.png');
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
}
.main-container .bottom-background-container .bottom-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 0 auto;
  padding-left: 512px;
  */
  padding-right: 128px;
  font-size: 2.4rem;
  font-weight: 600;
  color: #fff;
}
.main-container
  .bottom-background-container
  .bottom-title-container
  .bottom-title-top {
  margin: 0 0 24px 0;
}
.main-container
  .bottom-background-container
  .bottom-title-container
  .bottom-title-bottom {
  margin: 0 0 48px 0;
}
.main-container
  .bottom-background-container
  .bottom-title-container
  .app-download-btn {
  border-radius: 16px;
  text-align: center;
  width: 200px;
  padding: 8px 12px;
  font-size: 2rem;
  color: white;
  background-color: var(--primary-color);
}
.main-container
  .bottom-background-container
  .bottom-title-container
  .download-container {
  display: flex;
}
.main-container
  .bottom-background-container
  .bottom-title-container
  .download-container
  .download-icon {
  width: 220px;
  margin: 0 12px;
}
.download-icon:hover {
  cursor: pointer;
}
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-360px);
  }
  50% {
    transform: translateX(-720px);
  }
  75% {
    transform: translateX(-1080px);
  }
  100% {
    transform: translateX(-1440px);
  }
}
@media (max-width: 1140px) {
  .main-container {
    width: auto;
  }
  .main-container .main-background-container {
    background-image: url('../public/images/1-mobile.png');
    background-position: center;
    height: 100vw;
  }
  .main-container .main-background-container .main-title-container {
    margin: 0 0 0 32px;
    padding-right: 100px;
    padding-left: 0;
    font-size: 7vw;
  }
  .main-container
    .main-background-container
    .main-title-container
    .main-title-top {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
  }
  .main-container
    .main-background-container
    .main-title-container
    .main-title-top
    .main-title-br {
    margin-top: 8px;
  }
  .main-container
    .main-background-container
    .main-title-container
    .main-title-bottom {
    margin-bottom: 24px;
  }
  .main-container
    .main-background-container
    .main-title-container
    .download-container
    .download-icon {
    margin-right: 8px;
    width: 22vw;
  }
  .main-container .vision-container {
    width: auto;
    padding: 32px;
  }
  .main-container .vision-container .vision-index {
    font-size: 4vw;
    margin-bottom: 24px;
  }
  .main-container .vision-container .vision-title {
    font-size: 7vw;
  }
  .main-container .vision-container .vision-text {
    display: block;
    font-size: 4vw;
    margin-bottom: 32px;
  }
  .main-container .vision-container .vision-image-container {
    height: 60vw;
    min-height: 0;
  }
  .main-container .vision-container .vision-image-container .vision-image-text {
    font-size: 4vw;
    margin-left: 32px;
  }
  .main-container .business-background .business-container {
    width: auto;
    padding: 32px;
  }
  .main-container .business-background .business-container .business-index {
    font-size: 4vw;
    margin-bottom: 24px;
  }
  .main-container
    .business-background
    .business-container
    .business-section-one {
    flex-direction: column;
    margin-bottom: 64px;
  }
  .main-container
    .business-background
    .business-container
    .business-section-one
    .business-content-container
    .business-title {
    font-size: 7vw;
  }
  .main-container
    .business-background
    .business-container
    .business-section-one
    .business-content-container
    .business-text {
    font-size: 4vw;
    margin-bottom: 32px;
  }
  .main-container
    .business-background
    .business-container
    .business-section-one
    .business-image {
    margin: 0;
  }
  .main-container
    .business-background
    .business-container
    .business-section-one
    .business-image
    img {
    height: 100vw;
  }
  .main-container
    .business-background
    .business-container
    .business-section-two {
    flex-direction: column;
    flex-flow: column-reverse;
    margin-bottom: 64px;
  }
  .main-container
    .business-background
    .business-container
    .business-section-two
    .business-content-container
    .business-title {
    display: flex;
    flex-direction: column;
    font-size: 7vw;
  }
  .main-container
    .business-background
    .business-container
    .business-section-two
    .business-content-container
    .business-title
    .business-title-wip {
    font-size: 4vw;
    margin-top: 8px;
    margin-bottom: 0;
  }
  .main-container
    .business-background
    .business-container
    .business-section-two
    .business-content-container
    .business-text {
    font-size: 4vw;
    margin-bottom: 32px;
  }
  .main-container
    .business-background
    .business-container
    .business-section-two
    .business-image {
    margin: 0;
  }
  .main-container
    .business-background
    .business-container
    .business-section-two
    .business-image
    img {
    height: 80vw;
  }
  .main-container .use-container {
    width: auto;
    padding: 32px;
  }
  .main-container .use-container .use-index {
    font-size: 4vw;
  }
  .main-container .use-container .use-section {
    margin-bottom: 32px;
  }
  .main-container .use-container .use-section .use-title {
    font-size: 7vw;
  }
  .main-container .use-container .use-section .use-content-container {
    flex-direction: column;
    align-items: center;
  }
  .main-container
    .use-container
    .use-section
    .use-content-container
    .use-image-slider {
    width: 300px;
    margin: 0;
  }
  .main-container
    .use-container
    .use-section
    .use-content-container
    .use-image-slider
    img {
    width: 300px;
  }
  .main-container
    .use-container
    .use-section
    .use-content-container
    .use-list-container {
    display: none;
  }
  .main-container
    .use-container
    .use-section
    .use-content-container
    .verticalDottedLine {
    display: none;
  }
  .main-container .use-container .use-section .space-container {
    margin-top: 64px;
  }
  .main-container .history-background .history-container {
    width: auto;
    padding: 32px;
  }
  .main-container .history-background .history-container .history-index {
    font-size: 4vw;
    margin-bottom: 0;
  }
  .main-container
    .history-background
    .history-container
    .history-align-container
    .history-content-container
    .history-section-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .main-container
    .history-background
    .history-container
    .history-align-container
    .history-content-container
    .history-section-container
    .history-year {
    font-size: 6vw;
    margin: 0 0 32px 0;
    min-width: 0;
  }
  .main-container
    .history-background
    .history-container
    .history-align-container
    .history-content-container
    .history-section-container
    .history-text-container {
    width: 100%;
  }
  .main-container
    .history-background
    .history-container
    .history-align-container
    .history-content-container
    .history-section-container
    .history-text-container
    .history-content {
    margin-bottom: 16px;
  }
  .main-container
    .history-background
    .history-container
    .history-align-container
    .history-content-container
    .history-section-container
    .history-text-container
    .history-content
    .history-season {
    margin-right: 8px;
    text-align: start;
    font-size: 5vw;
  }
  .main-container
    .history-background
    .history-container
    .history-align-container
    .history-content-container
    .history-section-container
    .history-text-container
    .history-content
    .history-text-container
    .history-text {
    font-size: 4vw;
    margin-top: 0;
  }
  .main-container .partner-container {
    width: auto;
    padding: 32px;
  }
  .main-container .partner-container .partner-index {
    font-size: 4vw;
  }
  .main-container .partner-container .image-container .partner-image {
    flex: 1 0 50%;
    max-width: 50%;
  }
  .main-container .news-background .news-container {
    width: auto;
    padding: 32px;
  }
  .main-container .news-background .news-container .news-index {
    font-size: 4vw;
  }
  .main-container .news-background .news-container .news-section .news-title {
    margin-top: 0;
  }
  .main-container
    .news-background
    .news-container
    .news-section
    .news-title
    .news-info-container {
    margin: 4px 0;
  }
  .main-container
    .news-background
    .news-container
    .news-section
    .news-title
    .news-info-container
    .news-date {
    font-size: 3vw;
  }
  .main-container
    .news-background
    .news-container
    .news-section
    .news-title
    .news-info-container
    .news-company {
    font-size: 3vw;
  }
  .main-container
    .news-background
    .news-container
    .news-section
    .news-title
    .news-link
    .news-link-title {
    font-size: 4vw;
  }
  .main-container
    .news-background
    .news-container
    .news-section
    .news-title
    .news-link
    .news-arrow-icon {
    font-size: 4vw;
  }
  .main-container .newsletter-container {
    width: auto;
    padding: 32px;
  }
  .main-container .newsletter-container .newsletter-index {
    font-size: 4vw;
  }
  .main-container .newsletter-container .newsletter-title {
    font-size: 6vw;
  }
  .main-container .newsletter-container #stb_subscribe * {
    font-size: 3vw;
  }
  .main-container .newsletter-container #stb_subscribe {
    width: 100%;
  }
  .main-container .newsletter-container #stb_form_set_input {
    font-size: 3vw;
  }
  .main-container .newsletter-container #stb_form_submit_button {
    font-size: 4vw !important;
  }
  .main-container .newsletter-container #stb_subscribe .stb_form_msg_error {
    font-size: 4vw;
  }
  .main-container .newsletter-container #stb_subscribe .stb_form_policy * {
    font-size: 3vw !important;
  }
  .main-container
    .newsletter-container
    #stb_subscribe
    .stb_form_result.success
    p {
    font-size: 4vw;
  }
  .main-container .newsletter-container #stb_subscribe .stb_form_result.fail p {
    font-size: 4vw;
  }
  .main-container .bottom-background-container {
    background-image: url('../public/images/5-mobile.png');
    height: 100vw;
    background-size: cover;
    background-position: left;
    width: auto;
    margin: 32px;
    padding: 32px;
  }
  .main-container .bottom-background-container .bottom-title-container {
    padding-right: 0;
    font-size: 5vw;
  }
  .main-container
    .bottom-background-container
    .bottom-title-container
    .bottom-title-top {
    display: none;
    text-align: center;
    margin-bottom: 4px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  }
  .main-container
    .bottom-background-container
    .bottom-title-container
    .bottom-title-bottom {
    display: none;
    margin-bottom: 16px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  }
  .main-container
    .bottom-background-container
    .bottom-title-container
    .download-container {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .main-container
    .bottom-background-container
    .bottom-title-container
    .download-container
    .download-icon {
    width: 28vw;
    margin: 0 0 16px 0;
  }
}

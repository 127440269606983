.terms-of-service {
  max-width: 1140px;
  margin: 0 auto;
  padding: 20px;
  font-size: 16px;
  line-height: 1.5;
}

.terms-of-service.mobile {
  font-size: 14px;
}

.main-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 20px;
}

.section-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.section-title.mobile {
  font-size: 16px;
  background-color: #f0f0f0;
  padding: 6px 10px;
  border-radius: 5px;
}

/*.section-content {*/
/*  margin-left: 20px;*/
/*}*/

/*.section-content.mobile {*/
/*  margin-left: 10px;*/
/*}*/

ol {
  list-style-type: decimal;
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
}

.appendix {
  margin-top: 20px;
}

.version-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.version-dropdown {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}

.version-dropdown:focus {
  outline: none;
  border-color: #007bff;
}

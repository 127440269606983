.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;
}

.header .header-container {
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  width: 1080px;
  margin: 0 auto;
}

.header .header-container .logo-container {
  margin-right: 192px;
}

.header .header-container .logo-container .logo {
  height: 32px; /* 로고 이미지 높이 */
}

.header .header-container .hamburger {
  display: none;
  cursor: pointer;
  margin-right: 16px;
  width: 32px;
}

.header .header-container .navbar {
  padding: 0;
}

.header .header-container .navbar .nav-items {
  padding: 0;
  list-style: none;
  display: flex;
}

.header .header-container .navbar .nav-items .nav-list {
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
  margin-left: 32px;
}

.header .header-container .navbar .nav-items .nav-list:hover {
  color: var(--grey-700);
}

.header .header-menu.open {
  padding: 12px 0;
}

.header .header-menu.open .nav-items {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 4vw;
}

.header .header-menu.open .nav-items .nav-list {
  cursor: pointer;
  margin: 6px 0;
}

.header .header-menu.closed {
  display: none;
}

@media (max-width: 1140px) {
  .header .header-container {
    height: 56px;
    justify-content: space-between;
    width: auto;
  }

  .header .header-container .logo-container {
    margin-right: 0;
  }

  .header .header-container .logo-container .logo {
    margin-left: 16px;
    height: 24px;
  }

  .header .header-container .hamburger {
    display: block;
    width: 24px;
  }

  .header .header-container .navbar {
    display: none;
  }
}

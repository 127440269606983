.event-modal {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 55%;
  left: 30%;
  transform: translate(-50%, -50%);
  border: 3px solid var(--grey-200);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* border: 1px solid black;
  */
}
.event-modal .event-link {
  padding: 0;
  height: 640px;
}
.event-modal .event-link .event-image {
  width: 640px;
}
.event-modal .event-close-group {
  display: flex;
  background-color: var(--grey-200);
  justify-content: space-between;
  padding: 4px 8px;
}
.event-modal .event-close-group .event-close-for-day {
  color: var(--grey-900);
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.event-modal .event-close-group .event-close-for-day:hover {
  color: var(--grey-700);
}
.event-modal .event-close-group .event-close-btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.event-modal .event-close-group .event-close-btn:hover {
  color: var(--grey-700);
}
@media (max-width: 1140px) {
  .event-modal {
    top: 50%;
    left: 50%;
    margin-top: 56px;
    top: 45%;
    width: 80vw;
  }
  .event-modal .event-link {
    height: 80vw;
  }
  .event-modal .event-link .event-image {
    width: 80vw;
  }
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.modal-background .modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f5f5f5;
  padding: 20px;
  z-index: 1000;
  border-radius: 24px;
  margin: 32px 0;
  height: 80vh;
}
.modal-background .modal-container .modal-close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: black;
}
@media (max-width: 1140px) {
  .modal-background .modal-container {
    height: 100vw;
    margin: 56px 0 0;
    top: 0%;
    left: 0%;
    transform: none;
    border-radius: 0;
  }
  .modal-background .modal-container .modal-close-btn {
    font-size: 5vw;
  }
}

/* 푸터 */
.footer {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 1080px;
  padding: 64px 0;
  margin: 0 auto;
}
.footer .footer-section {
  display: flex;
  align-items: flex-start;
}
.footer .footer-section .footer-logo {
  width: 120px;
  margin-right: 32px;
}
.footer .footer-section .footer-container {
  margin-right: 64px;
}
.footer .footer-section .footer-container .footer-text {
  font-size: 0.8rem;
  color: var(--grey-700);
  margin-bottom: 8px;
}
.footer .footer-section .footer-container .footer-bold {
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 16px;
}
.footer .footer-section .footer-container .link-info {
  display: flex;
}
.footer .footer-section .footer-container .link-info .link-item {
  font-size: 0.8rem;
  margin-right: 4px;
}
.footer .footer-section .footer-container .link-info .link-item a {
  color: var(--grey-700);
}
.footer .footer-section .footer-sns-icons .footer-icon-image {
  margin-right: 16px;
  width: 32px;
  height: auto;
}
@media (max-width: 1140px) {
  .footer {
    padding: 0;
    width: 100vw;
    flex-direction: column;
  }
  .footer .footer-section {
    flex-direction: column;
    padding: 32px;
  }
  .footer .footer-section .footer-logo {
    margin: 0 0 16px 0;
  }
  .footer .footer-section .footer-container {
    margin-bottom: 24px;
  }
}

/*문의하기*/
.inquiry-container {
  padding: 32px;
}
.inquiry-container .inquiry-index {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 48px;
}
.inquiry-container .inquiry-title {
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 48px;
}
.inquiry-container #email {
  border: none;
  outline: none;
  width: 480px;
  height: 24px;
  padding: 12px;
  font-size: 1.2rem;
  border-radius: 12px;
  background-color: white;
  margin-bottom: 24px;
  margin-right: 16px;
}
.inquiry-container #message {
  border: none;
  outline: none;
  width: 720px;
  height: 36vh;
  padding: 12px;
  font-size: 1.2rem;
  border-radius: 12px;
  background-color: white;
  margin-bottom: 24px;
}
.inquiry-container .send-button {
  width: 200px;
  height: 48px;
  font-weight: 600;
  font-size: 1.6rem;
  border-radius: 12px;
  border: none;
  background-color: var(--grey-700);
  color: white;
}
@media (max-width: 1140px) {
  .inquiry-container {
    padding: 48px 0 0 0;
  }
  .inquiry-container .inquiry-title {
    font-size: 6vw;
    margin-bottom: 24px;
  }
  .inquiry-container .form-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .inquiry-container .form-container .send-container #email {
    font-size: 4vw;
    width: 80vw;
  }
  .inquiry-container .form-container #message {
    width: 80vw;
    height: 24vw;
    font-size: 4vw;
  }
  .inquiry-container .form-container .send-button {
    font-size: 5vw;
    margin-bottom: 16px;
  }
}

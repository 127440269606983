.event-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: var(--grey-200);
  */
}
.event-page .sticky-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  width: 100%;
  height: 72px;
  background-color: var(--grey-200);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.event-page .sticky-header .logo {
  margin-left: 32px;
  height: 40px;
}
.event-page .sticky-header .app-download-link {
  background-color: var(--primary-color);
  border-radius: 32px;
  font-size: 24px;
  font-weight: bold;
  color: white;
  padding: 12px 20px;
  margin-right: 32px;
  text-decoration: none;
}
.event-page .back-btn-container {
  display: flex;
  justify-content: space-between;
  width: 60vw;
}
.event-page .back-btn-container .link-back-btn,
.event-page .back-btn-container .link-close-btn {
  text-align: center;
  align-content: center;
  color: var(--grey-900);
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-decoration: none;
}
.event-page .back-btn-container .link-close-btn {
  font-size: 48px;
}
.event-page .back-btn-container .link-back-btn {
  font-size: 60px;
}
.event-page .back-btn-container .link-back-btn:hover,
.event-page .back-btn-container .link-close-btn:hover {
  color: var(--grey-700);
}
.event-page .event-image {
  margin: 16px;
  border: 3px solid var(--grey-100);
  width: 60vw;
}
@media (max-width: 1140px) {
  .event-page .sticky-header {
    height: 60px;
  }
  .event-page .sticky-header .logo {
    margin-left: 16px;
    height: 28px;
  }
  .event-page .sticky-header .app-download-link {
    border-radius: 24px;
    font-size: 16px;
    padding: 8px 16px;
    margin-right: 16px;
  }
  .event-page .back-btn-container {
    width: 90vw;
  }
  .event-page .back-btn-container .link-close-btn {
    font-size: 32px;
  }
  .event-page .back-btn-container .link-back-btn {
    font-size: 40px;
  }
  .event-page .event-image {
    width: 90vw;
  }
}

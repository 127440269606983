.stage-container {
  display: none;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 24px;
}
.stage {
  display: flex;
  width: 7vw;
  min-width: 7vw;
  height: 7vw;
  min-height: 7vw;
  background-color: var(--primary-color);
  color: #fff;
  font-size: 6vw;
  font-weight: bold;
  border-radius: 2vw;
  align-items: center;
  justify-content: center;
  margin: 0 12px 0 0;
}
.stage-text {
  font-size: 3.4vw;
  font-weight: bold;
}
.slide-image {
  max-width: 240px;
}
@media (max-width: 1140px) {
  .stage-container {
    display: flex;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.modal-backdrop .modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--grey-100);
  padding: 20px;
  z-index: 1000;
  border-radius: 24px;
  margin: 32px 0;
  width: 20vw;
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal-backdrop .modal-content button {
  position: absolute;
  top: 24px;
  right: 24px;
  background: transparent;
  border: none;
  font-size: 1.6rem;
  cursor: pointer;
  color: black;
}
.modal-backdrop .modal-content .modal-title {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 16px;
}
.modal-backdrop .modal-content .modal-text {
  font-size: 1.2rem;
}
@media (max-width: 1140px) {
  .modal-backdrop .modal-content {
    width: 72vw;
  }
}
